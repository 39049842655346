<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <img
            src="logo_coffix_low.png"
            alt="Logo"
          >

          <!--          <h2 class="brand-text text-primary ml-1 mt-1">-->
          <!--            Coffix-->
          <!--          </h2>-->
        </b-link>

        <b-card-title class="mb-1">
          Benvenuto su Coffix! 👋
        </b-card-title>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="login"
          >

            <!-- codice -->
            <b-form-group
              label-for="codice"
              label="Codice"
            >
              <validation-provider
                #default="{ errors }"
                name="Codice"
                rules="required"
              >
                <b-form-input
                  id="codice"
                  v-model="userCode"
                  name="login-codice"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Codice"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Password</label>
                <b-link :to="{name:'forgot-password'}">
                  <small>Password dimenticata?</small>
                </b-link>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
            >
              Entra
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BCardTitle, BForm, BButton, VBTooltip,
  BCard,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { getHomeRouteForLoggedInUser, isUserLoggedIn } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import CryptoJS from 'crypto-js'
import store from '@/store'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardTitle,
    BForm,
    BButton,
    BCard,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userCode: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),

      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    if (isUserLoggedIn()) {
      this.$router.push({ name: 'dashboard' })
    }
  },
  beforeCreate() {
    if (isUserLoggedIn()) {
      this.$router.push({ name: 'dashboard' })
    }
  },
  create() {
    if (isUserLoggedIn()) {
      this.$router.push({ name: 'dashboard' })
    }
  },
  methods: {
    login() {
      this.$refs.loginForm.validate()
        .then(success => {
          if (success) {
            axios.post(`${process.env.VUE_APP_APIURL}login`, {
              code: this.userCode,
              password: this.password,
            })
              .then(response => {
                let userData = response.data.data.staff
                if (userData === undefined) {
                  userData = response.data.data.user
                }
                localStorage.setItem('userData', CryptoJS.AES.encrypt(JSON.stringify(userData), process.env.VUE_APP_CRYPT)
                  .toString())
                localStorage.setItem('accessToken', response.data.data.token)
                this.$ability.update(userData.ability)

                if (this.$can('store', 'Orders')) {
                  // Aggiorno il numero di elementi nel carrello
                  store.commit('ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.shop_cart_items_count)
                }

                this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
                  .then(() => {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: `Benvenuto ${userData.fullName || userData.username}`,
                        icon: 'UserIcon',
                        variant: 'success',
                      },
                    })
                  })
              })
              .catch(error => {
                this.$refs.loginForm.setErrors(error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: error.response.data.message,
                    icon: 'UserIcon',
                    variant: 'warning',
                  },
                })
              })
          }
        })
    },
  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/pages/page-auth.scss';
</style>
